import React, { useCallback, useEffect, useRef, useState, FC } from 'react';
import styled from 'styled-components';
import { HealthMonitorCodes, SessionState } from '@binah/web-sdk';
import { useMediaPredicate } from 'react-media-hook';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { Button, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { DialogProps } from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AppBar, Toolbar } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';

import { useError, useInfo, useLicenseKey, useMeasurementDuration, useMonitor, usePageVisibility, usePrevious, useWarning } from '../hooks';
import { mirror } from '../style/mirror';
import { Flex } from './shared/Flex';
import Timer from './Timer';
import media from '../style/media';
import Mask from '../assets/mask.svg';

import makeCarousel from 'react-reveal/makeCarousel';
import Slide from 'react-reveal/Slide';
import Cardiogram from 'react-cardiogram';
import Stats from './Stats';
import StartButton from './StartButton';
import Loader from './Loader';
import { VideoReadyState } from '../types';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


//interface for getting the vitail signs readind on this screen

interface VitalSign<T> {
  value: T;
  isEnabled: boolean;
}

interface IStats {
  /**
   *  Object - contains health stats info
   */
  vitalSigns: {
    heartRate: VitalSign<number>;
    breathingRate: VitalSign<number>;
  };

  measureEnd: boolean;
  textColor: String;
}



const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80px;
 
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MonitorWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 7.55;
  ${media.tablet`
    height: 100%;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.tablet`
    width: 812px;
    height: 609px;
  `}
  ${media.wide`
    width: 1016px;
    height: 762px;
  `}
`;

const VideoWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  z-index: -1;
`;

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  ${media.desktop`
      object-fit: contian;
  `}
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mirror}
 
`;

const ButtonWrapper = styled(Flex)`
  flex: 2;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 18px;
  ${media.tablet`
    padding: 0;
    height: auto;
    width: auto;
    position: absolute;
    right: 0;
    margin-right: 60px;
  `}
`;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  flex: 0.45;
  width: 100%;
  display: flex;
  align-items: flex-end;
`;


//setting the carosel slider for text messages
const CarouselUI = ({ children }) => <Container>{children}</Container>;
const Carousel = makeCarousel(CarouselUI);




const BinahMonitor = ({ showMonitor, cameraId, onLicenseStatus, onSettingsClick }) => {

  if (!showMonitor) {
    return null;
  }


  //modal controls
  const [openBest, setOpenBest] = React.useState(false);
  const handleOpenBest = (scrollType: DialogProps['scroll']) => () => {
    setOpenBest(true);
    setScroll(scrollType);
  };



  const handleCloseBest = () => setOpenBest(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  //timer props
  const [key, setKey] = useState(0);                              //this reset the timmer in case of any errors occured
  const [mainScanDuration, setMainScanDuration] = useState(70);   //this is main duration the scanner will run for.


  const [progress, setProgress] = React.useState(0);


  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openBest]);






  const video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(false);
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate('(min-width: 1000px)');
  const isMobileView = useMediaPredicate('(min-width: 768px)');
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const [measureEnd, setMeasureEnd] = useState<boolean>(false);



  //state variables for token validation
  const [tokenValidation, setTokenValidation] = useState(false)
  const [isLoadingPage, setIsloadingPage] = useState(true)
  const [startBtn, setStartBtn] = useState(false)
  const [myToken, setMyToken] = useState('')

  const [timeoutSession, setTimeoutSession] = useState(10000)

  const { sessionState, vitalSigns, offlineMeasurements, error, warning, info, } = useMonitor(
    video,
    cameraId,
    processingTime,
    licenseKey,
    null,
    startMeasuring,
  );


  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);
  const infoMessage = useInfo(info);

  const isMeasuring = useCallback(() => sessionState === SessionState.MEASURING, [sessionState]);
  const isVideoReady = useCallback(() => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA, []);

  const [startOne, setStartOne] = useState<Number>(0);




  React.useEffect(() => {

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [sessionState]);


  const handleButtonClick = useCallback(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('payload')
    if (sessionState === SessionState.ACTIVE) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "payload": code
      });

      let requestOptions: object
      requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch("https://europe-west2-binnah-c256c.cloudfunctions.net/binnah/success", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("Result")
        })
        .catch(error => console.log('error', error));


      setIsLoading(true);
      setStartMeasuring(true);
    }
    else if (isMeasuring()) {

      setMeasureEnd(true)
      setStartMeasuring(false);
    }
  }, [sessionState]);



  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      }
      else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
    }

    else if ((sessionState === SessionState.ACTIVE || sessionState === SessionState.TERMINATED) && errorMessage) {
      setKey(prevKey => prevKey + 1)
      setIsMeasurementEnabled(false);
    }

    if (sessionState === SessionState.ACTIVE && prevSessionState !== sessionState) {
      setKey(prevKey => prevKey + 1)
      setStartMeasuring(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);



  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);







  //validate token to check if the request is from the correct server.
  const validateToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('payload')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "payload": code,
    });
    let requestOptions: object
    requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://europe-west2-binnah-c256c.cloudfunctions.net/binnah/validateToken", requestOptions)
      .then(response => response.json())
      .then(result => {
        setIsloadingPage(false)
        if (result.status == 1) {
          setTokenValidation(true)
          setMyToken(code)
        } else {
          setTokenValidation(false)
          setMyToken('')
        }
        console.log(result)
      })
      .catch(error => {
        setIsloadingPage(false)
        setTokenValidation(false)
        setMyToken('')
      });

  }

  //call to the validate token method
  useEffect(() => {
    validateToken()
  }, [])

  //showing the count down timer
  const renderTime = ({ remainingTime }) => {

    if (remainingTime === 0) {
      return <div className="d-flex flex-column">
        <div>
          <div className="play-button-inner">
            {/* <i className={isMeasuring() ? "fa fa-pause fa-2x" : "fa fa-play fa-2x"} /> */}
          </div>
        </div>
      </div>;
    }
    return (
      <div className="d-flex flex-column">
        <div>
          <div className="play-button-inner">
            00:{remainingTime}
          </div>
        </div>
      </div>
    );
  };


  //the alert dia log setup only
  const [open, setOpen] = React.useState(false);
  const [clientStoppedMesure, setIsClientStopMeasure] = useState(false)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  //stops the scanner
  const handleClose = () => {
    setMeasureEnd(true)
    setStartMeasuring(false);
    setOpen(false);
    setIsClientStopMeasure(true)
  };

  const continueScan = () => {
    setIsClientStopMeasure(false)
    setMeasureEnd(false)
    setStartMeasuring(true);
    setMainScanDuration(0)
  }

  const startNewScan = () => {
    setIsClientStopMeasure(false)
    setMeasureEnd(false)
    setStartMeasuring(true);
    setMainScanDuration(70)
  }
  //continue the scanner. 
  const continueScanner = () => {
    setStartMeasuring(true);
    setOpen(false);
    setMainScanDuration(0)
  }

  let inteactionMessages = ['Hi there, We are chekcing your vitials.', 'Please wait we are checking your current heart beat', 'Please wait we are looking for your blood preasure', 'Please wait while get your breathing rate.']


  return (
    <>
      {isLoadingPage ? <p>Loading...</p> :

        <>
          {
            !tokenValidation ?
              <div className='mainbody'>
                <div className="lock" />
                <div className="message" style={{ display: 'contents' }}>
                  <h1>ERROR CODE: "HTTP 403 Forbidden - Access Denied"</h1>
                  <p style={{ fontSize: 12, marginBottom: 20 }}>You Do Not Have The Permission To Access This Page On This Server</p>
                </div>
              </div>
              :
              <>
                <div className="video-outer-Container">
                  {/* Main success apge */}
                  <div style={{ display: clientStoppedMesure ? 'block' : 'none' }} className="card">
                    <div style={{ textAlign: 'center', borderRadius: 200, height: 100, width: 100, background: '#f1531c17', margin: '0 auto' }}>
                      <i className="checkmark">✓</i>
                    </div>
                    <h1 className='mainheading'>Success</h1>
                    <p>You have successfully recorded your vitals. Here are quick stats of your vital results.</p>
                    {/* <button onClick={() => continueScan()}>Continue Scan</button> */}
                    <Stack direction="row" spacing={2}>
                      <Button size='small' onClick={() => startNewScan()} variant="outlined" color="primary"> Start new Scan </Button>
                      <Button size='small' onClick={() => continueScan()} variant="outlined" color="primary"> Continue current Scant</Button>
                    </Stack>
                  </div>


                  <div className="error-text-row">
                    {
                      errorMessage && <div style={{ fontSize: '12px', marginBottom: 1, height: 'auto', lineHeight: '15px', padding: '8px 15px' }} className="alert alert-danger" role="alert">{errorMessage}</div>
                    }
                    {
                      isMeasuring() && infoMessage === '' && warningMessage && <div style={{ fontSize: '12px', marginBottom: 1, height: 'auto', lineHeight: '15px', padding: '8px 15px' }} className="alert alert-warning Toast" role="alert">{warningMessage}</div>
                    }

                    {
                      isMeasuring() && infoMessage && <div style={{ fontSize: '12px', marginBottom: 1, height: 'auto', lineHeight: '15px', padding: '8px 15px' }} className="alert alert-info Toast" role="alert">{infoMessage}</div>
                    }
                  </div>

                  {/* Main Video container page */}
                  <div style={{ display: clientStoppedMesure && 'none' }} className="video-Container">
                    <div className="video-box">



                      {/* <div id="content">
                        <div className="overlay">
                          <div className="overlay-element top-left" />
                          <div className="overlay-element top-right" />
                          <div className="overlay-element bottom-left" />
                          <div className="overlay-element bottom-right" />
                        </div>
                      </div> */}


                      <div id="content">
                        <div className="overlay">
                          <div className="overlay-helper">
                            <div className="overlay-element top-left">
                              <span id="overlay-top-left-text" className="overlay-text">
                                {isMeasuring() ? '🔴 Scanning' : '🔴 Stopped'}
                              </span>
                            </div>
                            <div className="overlay-element top-right">
                              <span id="overlay-top-right-text" className="overlay-text">
                                <Timer started={isMeasuring()} durationSeconds={processingTime} />
                              </span>
                            </div>
                            <div className="overlay-element bottom-left">
                              <span id="overlay-bottom-left-text" className="overlay-text">

                              </span>
                            </div>
                            <div className="overlay-element bottom-right">

                            </div>
                          </div>
                        </div>
                        <VideoWrapper>
                          <Video ref={video} id="video" muted={true} playsInline={true} />
                        </VideoWrapper>
                      </div>

                      {/* <div id="content">
                        <div className="overlay">
                          <div className="overlay-helper">
                            <div className="overlay-element top-left" />
                            <div className="overlay-element top-right" />
                            <div className="overlay-element bottom-left" />
                            <div className="overlay-element bottom-right" />
                          </div>
                        </div>

                        <Video ref={video} id="video" muted={true} playsInline={true} />
                      </div> */}



                      {/* <VideoWrapper>
                        <Img src={Mask} />
                        <Video ref={video} id="video" muted={true} playsInline={true} />
                      </VideoWrapper> */}
                    </div>
                  </div>

                  {

                    !isMeasuring() ?
                      //if the measurement has not been started , we will show the buttons to get started
                      <div style={{ display: clientStoppedMesure && 'none' }} className='measure-container'>
                        <div style={{ textAlign: 'center' }}>
                          <h4>Bring your health data togather</h4>
                          <p>Start your scan to check your vital signs now</p>
                        </div>
                        <Button onClick={handleButtonClick} variant="contained">Start Measuring</Button>
                      </div>

                      :
                      //once the measurements are started, rendering will start to take place
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                          <div>
                            <p style={{ margin: 0 }}>Measurements in Progress</p>
                            <p style={{ margin: 0 }}><Timer started={isMeasuring()} durationSeconds={processingTime} /></p>
                          </div>
                          <CountdownCircleTimer
                            isPlaying={isMeasuring()}
                            duration={mainScanDuration}
                            size={50}
                            colors={['#57a773', '#484D6D', '#08B2E3', '#EFE9F4', '#EE6352']}
                            colorsTime={[70, 50, 30, 20, 10]}
                            key={key}
                            strokeWidth={0}
                            onComplete={() => {
                              //alert("This si coole call the function heer....")
                              setMeasureEnd(true)
                              //setStartMeasuring(false);
                              setOpen(true)
                              return { shouldRepeat: false, delay: 1.5 } // repeat animation in 1.5 seconds
                            }}
                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                        <LinearProgress />
                        <div style={{ padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Carousel defaultWait={3000} maxTurns={20} >
                            {
                              inteactionMessages.map((item, index) => {
                                return (
                                  <Slide right>
                                    <div>
                                      <p>{item}</p>
                                    </div>
                                  </Slide>
                                )

                              })
                            }
                          </Carousel>
                          <h1>{vitalSigns.heartRate.isEnabled ? vitalSigns.heartRate.value || '--' : 'N/A'} bmp</h1>
                        </div>
                        {/* <Cardiogram
                          width={500}
                          height={100}
                          color={'#EE6352'}
                          thickness={2}
                          scale={35}
                          cursorSize={3}
                          density={2}
                          paintInterval={30}
                          beatFrequency={1000}
                        /> */}
                      </div>
                  }







                  {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <Timer started={isMeasuring()} durationSeconds={processingTime} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', }}>
                      <p style={{ fontSize: 10 }}><strong>BETA Release v1.0.3</strong></p>
                      <IconButton onClick={handleOpenBest('paper')} aria-label="delete" size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </div> */}



                  {clientStoppedMesure && <Stats textColor={'#000'} vitalSigns={vitalSigns} measureEnd={measureEnd} />}






                  <Dialog
                    // fullScreen={fullScreen}
                    open={open}
                    //onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {"Vital Scan Completed"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Sufficient data has been acquired to calculate your vital signs. However, to further refine the scan results you may continue the scanning for next 8 mins. Do you want to finish scanning or continue?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleClose}>
                        Submit Current Vitals
                      </Button>
                      <Button onClick={continueScanner} autoFocus>
                        Continue Scanning
                      </Button>
                    </DialogActions>
                  </Dialog>


                  <Dialog
                    open={openBest}
                    onClose={handleCloseBest}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullScreen={true}
                  >
                    <AppBar sx={{ position: 'relative' }}>
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleCloseBest}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                          Best Pratices
                        </Typography>

                      </Toolbar>
                    </AppBar>
                    {/* <DialogTitle id="scroll-dialog-title">Best Pratices</DialogTitle> */}
                    <DialogContent dividers={scroll === 'paper'}>
                      <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        style={{ padding: 10 }}
                      >
                        <div>
                          <h3 id="rppg-face-scan-0-0"><span className="wysiwyg-color-black">rPPG - Face scan</span></h3>
                          <p>In order to get accurate vital-sign readings, follow the guidelines below:</p>
                          <h4 id="device-0-1"><span className="wysiwyg-color-black"><strong>Device</strong></span></h4>
                          <ul>
                            <li>Ensure battery level is above 20% and not in power-save mode.</li>
                            <li>Ensure your camera lenses are clean and unscratched.</li>
                            <li>Place device on a stand about 30 cm away from your face.</li>
                            <li>Position the device with the selfie camera at eye level and parallel to<br />the face.</li>
                            <li>Use a stand to reduce shaking and movements of the device.</li>
                          </ul>
                          <h4 id="subject-0-2"><span className="wysiwyg-color-black"><strong>Subject</strong></span></h4>
                          <ul>
                            <li>Sit still during the measurement.</li>
                            <li>Ensure your feet are placed flat on the floor and your legs are<br />uncrossed.</li>
                            <li>Make sure your face is fully exposed, ensuring it is not covered by hair or accessories (i.e., mask, sunglasses, hat).</li>
                            <li>Avoid moving or talking throughout the measurement and remain focused on the screen until the measurement is complete.</li>
                          </ul>
                          <h4 id="environmental-conditions-0-3"><span className="wysiwyg-color-black"><strong>Environmental Conditions</strong></span></h4>
                          <ul>
                            <li>Ensure your face is evenly illuminated</li>
                            <li>Avoid measuring in locations with multiple light sources (preferably a<br />single light source directed towards the face).</li>
                            <li>Settle into a position where no light sources are directed toward the<br />camera (e.g., by sitting against a wall).</li>
                            <li>Ensure neither the camera nor the area in the frame is exposed to<br />direct sunlight.</li>
                            <li>Avoid movement of other persons behind or within your vicinity.</li>
                            <li>Avoid highly reflective objects in the surrounding area, including<br />mirrors, glass windows, tables, etc.</li>
                            <li>Ensure the level of the light illuminating your face is a minimum<br />of 400 lux.</li>
                            <li>It is recommended that you use a cold light (&gt;4500K) and not a<br />monochromatic light source.</li>
                          </ul>
                        </div>

                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseBest}>Close</Button>

                    </DialogActions>
                  </Dialog>
                </div>

              </>



          }
        </>
      }
    </>
  );
};

export default BinahMonitor;
