import React from 'react';
import styled from 'styled-components';
import { useMediaPredicate } from 'react-media-hook';
import { FlexSpace } from './shared/FlexSpace';

const Box = styled(FlexSpace)`
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: 40px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #01061b;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

const Value = styled.div`
  font-size: 14px;
  color: #fff;
  font-weight: 700;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StatsBox = ({ title, value, unit, icon, textColor, refRange }: any) => {

  const isMobileView = useMediaPredicate('(min-width: 768px)');
  return (


    <div className="rating-box">
      <div className="rating-inner-box">
        <div className="left-panel-text">
          <div className="icon-section">
            <img src={icon} />
          </div>
          <div className="rating-text-section">
            <p className="rating-heading"> {title} </p>
            <p className="rating-number">  {value && value} <span> {unit} </span> </p>
          </div>
        </div>
        <div className="right-panel-text">
          <div className="rating-text-right-section-container">
            <p className="ref-range-text"> Ref Range </p>
            <p className="ref-range-number"> {refRange} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsBox;
