import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`

*{
  box-sizing: border-box;
}

body{
  margin: 0;
  padding:0;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

.video-outer-Container{
  width: 45%;
  margin: 0 auto;
}

.video-Container{

  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
}

.video-box{
  width: 100%;
  float: left;
  position: relative;
}

.video-img{
  width: 100%;
  height: 100%;
}

.text-detail-container{
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 15px 0;
}

.row-margin-zero {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.play-button{
  position: absolute;
  bottom: 20px;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  width: 80px;
  height: 80px;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.play-button img{
  width: 100px;
}

.play-icon{
  font-size: 20px;
}

.error-text-row p{
text-align: center;
color: #fff;
position: absolute;
  top: 0;
  text-align: left;
  width: 100%;
  background: rgb(255 0 10 / 70%);
  padding: 7px;
margin:0;
font-size:14px;
}

.rating-box-container{
  width: 100%;
  float: left;
}

.rating-box{
  
  padding: 0 15px;
  width: 50%;
  float: left;
  margin-bottom: 15px;
}

.rating-inner-box{
  background-color: #fff;
  /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.10); */
  padding: 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 6px -2px #000000;
  box-shadow: 0px 1px 6px -2px #000000;
}

.left-panel-text{
  flex: 1;
  display: flex;
  align-items: center;
}

.right-panel-text{
  flex: 30% 0 0;
  justify-content: flex-end;
  display: flex;
}

.icon-section{
  flex: 45px 0 0;

}

.rating-text-section{
  flex: 1;
}

.rating-heading{
  font-size: 16px;
  margin: 0;
  columns: #222222;
}

.rating-number{
  font-size: 40px;
  margin: 0;
  color: #3B3B3B;
}

.rating-number span{
  font-size: 18px;
  color: #585858;
}

.rating-text-right-section-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ref-range-text{
  font-size: 18px;
  color:#3E4A68 ;
  margin: 0;
}

.ref-range-number{
  font-size: 18px;
  color:#067D32 ;
  margin: 0;
}


@media (max-width: 1650px) {
  .video-outer-Container {
      width: 65%;
          }
}

@media (max-width: 1330px) {
  .video-outer-Container {
      width: 70%;
          }
}

@media (max-width: 1170px) {
  .video-outer-Container {
      width: 70%;
          }
}

@media (max-width: 885px) {
  .video-outer-Container {
      width: 100%;
          }
}


@media (max-width: 845px) {
  .video-outer-Container {
      width: 100%;
  }

  .rating-box {
      width: 100%;
  }
}

.measure-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  align-items: center;
  margin-top: 10px;
}


.mainheading {
  color: #f2541b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 25px;
  margin-bottom: 10px;
}
.successfontbinnah {
  color: #f1531c1a;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size:20px;
  margin: 0;
}
.checkmark {
color: #f2541b;
font-size: 50px;
line-height: 100px;
margin-left:-15px;
}
.card {
background: white;
padding: 10px;
border-radius: 4px;

margin: 0 auto;
}




.overlay {
  --border-style: 1px solid red;
  --border-space: 30px;

  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay-helper {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay-element {
  padding: 20px;
  width: 150px;
  height: 100px;
  position: absolute;
}

.overlay-text {
  font-size: 14px;
  color: white;
  font-family: monospace;
  width: 100%
}

.overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

.overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

#overlay-bottom-left-text {
  position: absolute;
  bottom: var(--border-space);
  left: var(--border-space);
}

#overlay-bottom-right-text {
  position: absolute;
  bottom: var(--border-space);
  right: var(--border-space);
}


.maincard {
	border: 1px solid #f3f3f3;
  width: 100%;
  padding: 5px;
   box-shadow: 2px 2px 5px #aaaaaa;
  
}

.heading {
border-bottom: 1px solid grey;
}
.maincontent{
display: inline-flex;
padding: 5px;
align-items: center;
}
.maincardcontent {
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 5px;
padding-left: 5px;
}
`;

export default GlobalStyle;
