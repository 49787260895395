import React from 'react';
import styled from 'styled-components';
import StatsBox from './StatsBox';
import { Flex } from './shared/Flex';
import { getOS } from '@binah/web-sdk';
import hrLogo from '../assets/images/heart-rate.png';
import bpLogo from '../assets/images/blood-pressure-rate.png';
import brLogo from '../assets/images/breathing-rate.png';
import pluseLogo from '../assets/images/pluse-rate.png';
import rarLogo from '../assets/images/recovery-ability-rate.png';
import slrLogo from '../assets/images/stress-level-rate.png';
import wellLogo from '../assets/images/stress-smily.png';




const Wrapper = styled(Flex)`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 13px 50px;
  bottom: 15px;
  box-sizing: border-box;
`;

const BoxesWrapper = styled(Flex)`
  gap: 30px;
`;

interface VitalSign<T> {
  value: T;
  isEnabled: boolean;
}

interface IStats {
  /**
   *  Object - contains health stats info
   */
  vitalSigns: {
    heartRate: VitalSign<number>;
    breathingRate: VitalSign<number>;
    stress: VitalSign<number>;
    hrvSdnn: VitalSign<number>;
    spo2: VitalSign<number>;
    wellnessIndex: VitalSign<number>;
    wellnessLevel: VitalSign<number>;
    stressIndex: VitalSign<number>;
    bloodPressure: VitalSign<BloodPressureValue>;
    pnsZone: VitalSign<number>;
    sd1: VitalSign<number>;
    sd2: VitalSign<number>;
  };

  measureEnd: boolean;
  textColor: String;
}

export type BloodPressureValue = {
  systolic: number;
  diastolic: number;
};

const Stats = ({ textColor, vitalSigns, measureEnd = true }: IStats) => {
  const bloodPressureToDisplay =
    vitalSigns.bloodPressure.value?.systolic &&
      vitalSigns.bloodPressure.value?.diastolic
      ? vitalSigns.bloodPressure.value.systolic +
      '/' +
      vitalSigns.bloodPressure.value.diastolic
      : '--';

  return (

    <div className="text-detail-container">
      <div className="rating-box-container">

        <StatsBox
          title={'HR'}
          unit={'BPM'}
          icon={hrLogo}
          textColor={textColor}
          value={
            vitalSigns.heartRate.isEnabled
              ? vitalSigns.heartRate.value || '--'
              : 'N/A'
          }
          refRange={'60 - 100'}
        />

        <StatsBox
          title={'BR'}
          unit={'BPM'}
          icon={brLogo}
          textColor={textColor}
          value={
            vitalSigns.breathingRate.isEnabled
              ? vitalSigns.breathingRate.value || '--'
              : 'N/A'
          }
          refRange={'12 - 20'}
        />


        {
          //showing stress level
          measureEnd && <StatsBox
            title={'Stress Level'}
            unit={'5 levels (1-5)'}
            icon={slrLogo}
            textColor={textColor}
            value={vitalSigns.stress.isEnabled ? vitalSigns.stress.value || '--' : 'N/A'}
            refRange={'1(Low) - 5(High)'}
          />
        }

        {
          //showing stress index
          measureEnd && <StatsBox
            title={'Stress Index'}
            unit={''}
            icon={slrLogo}
            textColor={textColor}
            value={vitalSigns.stressIndex.isEnabled ? vitalSigns.stressIndex.value || '--' : 'N/A'}
            refRange={'N/A'}
          />
        }

        {
          //showing wellness index
          measureEnd && <StatsBox
            title={'Wellness Index'}
            unit={'(10 is healthiest)'}
            icon={wellLogo}
            textColor={textColor}
            value={vitalSigns.wellnessIndex.isEnabled ? vitalSigns.wellnessIndex.value || '--' : 'N/A'}
            refRange={"1 - 10"}
          />
        }

        {
          //showing wellness level
          measureEnd && <StatsBox
            title={'Wellness Level'}
            unit={''}
            icon={wellLogo}
            textColor={textColor}
            value={vitalSigns.wellnessLevel.isEnabled ? vitalSigns.wellnessLevel.value || '--' : 'N/A'}
            refRange={"Low to High"}
          />
        }




        {
          //showing the bloodpreasure 
          measureEnd && <StatsBox
            title={'BP'}
            unit={'mmHg'}
            icon={bpLogo}
            textColor={textColor}
            value={vitalSigns.bloodPressure.isEnabled ? bloodPressureToDisplay : 'N/A'}
            refRange={"N/A"}
          />
        }

        {
          //showing the bloodpreasure 
          measureEnd && <StatsBox
            title={'Recovery Ability (PNS Zone)'}
            unit={'(High is healthiest)'}
            icon={rarLogo}
            textColor={textColor}
            value={vitalSigns.pnsZone.isEnabled ? vitalSigns.pnsZone.value || '--' : 'N/A'}
            refRange={"Low to High"}
          />
        }



        {
          //showing wellness level
          measureEnd && <StatsBox
            title={'SD1'}
            unit={''}
            icon={pluseLogo}
            textColor={textColor}
            value={vitalSigns.sd1.isEnabled ? vitalSigns.sd1.value || '--' : 'N/A'}
            refRange={"N/A"}
          />
        }


        {
          //showing wellness level
          measureEnd && <StatsBox
            title={'SD2'}
            unit={''}
            icon={pluseLogo}
            textColor={textColor}
            value={vitalSigns.sd2.isEnabled ? vitalSigns.sd2.value || '--' : 'N/A'}
            refRange={"N/A"}
          />
        }
      </div>
    </div>


    // <>
    //   <div className="video-outer-Container">
    //     <div className="rating-box-container">
    //       <StatsBox
    //         title={'HR'}
    //         unit={'BPM'}
    //         icon={'fa fa-heartbeat'}
    //         textColor={textColor}
    //         value={
    //           vitalSigns.heartRate.isEnabled
    //             ? vitalSigns.heartRate.value || '--'
    //             : 'N/A'
    //         }
    //       />

    //       <StatsBox
    //         title={'BR'}
    //         unit={'BPM'}
    //         icon={'fa fa-heartbeat'}
    //         textColor={textColor}
    //         value={
    //           vitalSigns.breathingRate.isEnabled
    //             ? vitalSigns.breathingRate.value || '--'
    //             : 'N/A'
    //         }
    //       />

    //       <StatsBox
    //         title={'SpO2'}
    //         unit={'BPM'}
    //         icon={'fa fa-heartbeat'}
    //         textColor={textColor}
    //         value={
    //           vitalSigns.spo2.value || '--'
    //           // vitalSigns.breathingRate.isEnabled
    //           //   ? vitalSigns.breathingRate.value || '--'
    //           //   : 'N/A'
    //         }
    //       />





    //       {
    //         //showing stress level
    //         measureEnd && <StatsBox
    //           title={'Stress Level'}
    //           unit={'5 levels (1-5)'}
    //           icon={'fa fa-smile-o'}
    //           textColor={textColor}
    //           value={
    //             vitalSigns.stress.isEnabled
    //               ? vitalSigns.stress.value || '--'
    //               : 'N/A'
    //           }
    //         />
    //       }

    //       {
    //         //showing stress index
    //         measureEnd && <StatsBox
    //           title={'Stress Index'}
    //           unit={'5 levels (1-5)'}
    //           icon={'fa fa-smile-o'}
    //           textColor={textColor}
    //           value={
    //             vitalSigns.stressIndex.isEnabled
    //               ? vitalSigns.stressIndex.value || '--'
    //               : 'N/A'
    //           }
    //         />
    //       }

    //       {
    //         //showing wellness index
    //         measureEnd && <StatsBox
    //           title={'Wellness Index'}
    //           unit={'BPM'}
    //           icon={'fa fa-heartbeat'}
    //           textColor={textColor}
    //           value={
    //             vitalSigns.wellnessIndex.isEnabled
    //               ? vitalSigns.wellnessIndex.value || '--'
    //               : 'N/A'
    //           }
    //         />
    //       }

    //       {
    //         //showing wellness level
    //         measureEnd && <StatsBox
    //           title={'Wellness Level'}
    //           unit={'BPM'}
    //           icon={'fa fa-heartbeat'}
    //           textColor={textColor}
    //           value={
    //             vitalSigns.wellnessLevel.isEnabled
    //               ? vitalSigns.wellnessLevel.value || '--'
    //               : 'N/A'
    //           }
    //         />
    //       }




    //       {
    //         //showing the bloodpreasure 
    //         measureEnd && <StatsBox
    //           title={'BP'}
    //           unit={'mmHg'}
    //           icon={'fa fa-tint'}
    //           textColor={textColor}
    //           value={
    //             vitalSigns.bloodPressure.isEnabled
    //               ? bloodPressureToDisplay
    //               : 'N/A'
    //           }
    //         />
    //       }
    //     </div>
    //   </div>

    // </>
  );
};

export default Stats;
