import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import monitor, {
  AlertData,
  EnabledVitalSigns,
  FaceSessionOptions,
  HealthMonitorCodes,
  HealthMonitorSession,
  OfflineMeasurements,
  SessionState,
  VitalSigns,
  VitalSignsResults,
} from '@binah/web-sdk';
import { AppErrorCode } from '../types';

const useMonitor = (
  video: MutableRefObject<HTMLVideoElement>,
  cameraId: string,
  processingTime: number,
  licenseKey: string,
  productId: string,
  startMeasuring: boolean,
) => {
  const [session, setSession] = useState<HealthMonitorSession>();
  const [sessionState, setSessionState] = useState<SessionState>();
  const [isMonitorReady, setIsMonitorReady] = useState<boolean>();
  const [enabledVitalSigns, setEnabledVitalSigns] = useState<EnabledVitalSigns>();
  const [offlineMeasurements, setOfflineMeasurements] = useState<OfflineMeasurements>();
  const [vitalSigns, setVitalSigns] = useState<VitalSigns | null>();

  const [error, setError] = useState<AlertData>({ code: -1 });
  const [warning, setWarning] = useState<AlertData>({ code: -1 });
  const [info, setInfo] = useState<AlertData>({ code: -1 });

  const updateVitalSigns = useCallback((vitalSigns) => {
    setVitalSigns((prev) => ({
      ...prev,
      ...vitalSigns,
    }));
  }, []);

  const onVitalSign = useCallback((vitalSign: VitalSigns) => {
    updateVitalSigns(vitalSign);
  }, []);

  const onFinalResults = useCallback((vitalSignsResults: VitalSignsResults) => {
    updateVitalSigns(vitalSignsResults.results);
    console.log("THe final results are ====>>>>>" + JSON.stringify(vitalSignsResults.results))



    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('payload')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "payload": code,
      "results": vitalSignsResults.results
    });

    var requestOptions: object = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://europe-west2-binnah-c256c.cloudfunctions.net/binnah/senddata", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
      .catch(error => console.log('error', error));


  }, []);

  const onError = (errorData: AlertData) => {
    setError(errorData);
  };

  const onWarning = (warningData: AlertData) => {
    if (warningData.code === HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING) {
      setVitalSigns(null);
    }

    if (warningData.code === HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING) {
      setInfo({ code: warningData.code });
    }

    else {
      setWarning(warningData);
    }
  };

  const onStateChange = useCallback((state: SessionState) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns: EnabledVitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback((offlineMeasurements: OfflineMeasurements) => {
    setOfflineMeasurements(offlineMeasurements);
  }, []);

  const onFaceDetected = useCallback((isRect: boolean) => {
    if (!isRect) {
      setInfo({
        code: AppErrorCode.MEASUREMENT_CODE_FACE_UNDETECTED_ERROR,
      });
    } else {
      setInfo({ code: -1 });
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await monitor.initialize({
          licenseKey,
          licenseInfo: {
            onEnabledVitalSigns,
            onOfflineMeasurement,
          },
        });
        console.log(`Initialized monitor`);
        setIsMonitorReady(true);
        setError({ code: -1 });
      } catch (e) {
        console.error('Error initializing HealthMonitor', e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey, productId]);

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !video.current) {
          return;
        }

        sessionState === SessionState.ACTIVE && session.terminate();

        const options: FaceSessionOptions = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onFaceDetected,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
        };

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error('Error creating a session', e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);

  return {
    sessionState,
    vitalSigns: {
      heartRate: {
        value: vitalSigns?.heartRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledHeartRate,
      },
      breathingRate: {
        value: vitalSigns?.breathingRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledBreathingRate,
      },
      stress: {
        value: vitalSigns?.stressLevel?.value,
        isEnabled: enabledVitalSigns?.isEnabledStressLevel,
      },
      hrvSdnn: {
        value: vitalSigns?.sdnn?.value,
        isEnabled: enabledVitalSigns?.isEnabledSdnn,
      },
      spo2: {
        value: 0, //vitalSigns?.oxygenSaturation.value, //TODO Spo2 is currently disabled by algo
        isEnabled: false, //enabledVitalSigns?.isEnabledSpo2,
      },
      bloodPressure: {
        value: vitalSigns?.bloodPressure?.value,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
      wellnessIndex: {
        value: vitalSigns?.wellnessIndex?.value,
        isEnabled: enabledVitalSigns?.isEnabledWellnessIndex,
      },
      wellnessLevel: {
        value: vitalSigns?.wellnessLevel?.value,
        isEnabled: enabledVitalSigns?.isEnabledWellnessLevel,
      },
      stressIndex: {
        value: vitalSigns?.stressIndex?.value,
        isEnabled: enabledVitalSigns?.isEnabledStressIndex,
      },
      pnsZone: {
        value: vitalSigns?.pnsZone?.value,
        isEnabled: enabledVitalSigns?.isEnabledPnsZone,
      },
      sd1: {
        value: vitalSigns?.sd1?.value,
        isEnabled: enabledVitalSigns?.isEnabledSd1,
      },
      sd2: {
        value: vitalSigns?.sd2?.value,
        isEnabled: enabledVitalSigns?.isEnabledSd2,
      },
    },
    offlineMeasurements,
    error,
    warning,
    info,
  };
};

export default useMonitor;
