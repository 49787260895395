import React, { useCallback, useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import BinahMonitor from './BinahMonitor';
import SettingsBars from './SettingsBars';
import { Flex } from './shared/Flex';
import { useCameras, useDisableZoom } from '../hooks';
import UAParser from 'ua-parser-js';



const Wrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Container = styled(Flex)`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const App = () => {
  const cameras = useCameras();
  const [openSettings, setOpenSettings] = useState(false);
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === 'mobile',
  );
  useDisableZoom();

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  const handleSettingsClick = useCallback(() => {
    setOpenSettings(true);
  }, []);

  const handleCloseSettings = useCallback(({ cameraId }) => {
    setCameraId(cameraId);
    setOpenSettings(false);
  }, []);

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  return (
   
      <Wrapper>
        <Container>
          <BinahMonitor
            showMonitor={!(isMobile && openSettings)}
            cameraId={cameraId}
            onLicenseStatus={updateLicenseStatus}
            onSettingsClick={handleSettingsClick}
          />
          <SettingsBars
            open={openSettings}
            onClose={handleCloseSettings}
            cameras={cameras}
            isLicenseValid={isLicenseValid}
          />
        </Container>
      </Wrapper>
   
  );
};

export default App;
